import { Typography } from '@mui/material';
import { Fragment } from 'react';
import { calcScaleScores } from './helpers';
import { FeedbackComponentProps } from './index';
import { select } from './select';

const ConfidenceScale = {
  'Not at all confident': 1,
  'A little confident': 2,
  'Somewhat confident': 3,
  'Very confident': 4,
};

const evaluateConfidence = calcScaleScores(
  [
    'S1#PSES-Q1',
    'S1#PSES-Q2',
    'S1#PSES-Q3',
    'S1#PSES-Q4',
    'S1#PSES-Q5',
    'S1#PSES-Q6',
    'S1#PSES-Q7',
    'S1#PSES-Q8',
    'S1#PSES-Q9',
    'S1#PSES-Q10',
  ],
  ConfidenceScale,
);

export const Confidence = (props: FeedbackComponentProps) => {
  const scores = evaluateConfidence(props.userSurvey);
  const confidentInAll = scores.every((s) => s >= 4);
  const confidentInOne = scores.some((s) => s >= 4);
  return (
    <section>
      <Typography variant="h5" color="primary" gutterBottom>
        Your parenting confidence
      </Typography>
      {select(
        <>
          <Fragment key="not-confident-heading">
            <Typography paragraph>
              Based on your answers, you may like to select the following modules, which cover topics you don’t feel
              very confident in:
            </Typography>
          </Fragment>
          <ul key="not-confident-list">
            <li>Connect</li>
            <li>Nurture roots & inspire wings</li>
            <li>Good friends = supportive relationships</li>
            <li>Raising good kids into great adults</li>
            <li>Calm versus conflict</li>
            <li>Good health habits for good mental health</li>
            <li>Partners in problem solving</li>
            <li>Breaking the anxiety cycle</li>
            <li>Maintaining the gains</li>
            <li>Understanding anxiety and depression</li>
          </ul>
          <Fragment key="confident-heading">
            <Typography paragraph>
              The following modules may be less relevant for you, because you already feel very confident in the topics
              they cover:
            </Typography>
          </Fragment>
          <ul key="confident-list">
            <li>Connect</li>
            <li>Nurture & inspire wings</li>
            <li>Good friends = supportive relationships</li>
            <li>Raising good kids into great adults</li>
            <li>Calm versus conflict</li>
            <li>Good health habits for good mental health</li>
            <li>Partners in problem solving</li>
            <li>Breaking the anxiety cycle</li>
            <li>Maintaining the gains</li>
            <li>Understanding anxiety and depression</li>
          </ul>
          <Fragment key="confident-in-all">
            <Typography paragraph>
              It's great that you’re feeling very confident in all of the topics. You're still welcome to choose any
              module that may be of interest to you—even the most confident parents have found it useful to pick up
              additional strategies from the PiP program. Just read about what each module covers, then make your
              selection!
            </Typography>
          </Fragment>
        </>,
      )
        .where('not-confident-heading', () => !confidentInAll)
        .where('not-confident-list', (_, i) => (scores?.[i] ?? 0) < 4)
        .where('confident-heading', () => !confidentInAll && confidentInOne)
        .where('confident-list', (_, i) => !confidentInAll && (scores?.[i] ?? 0) >= 4)
        .where('confident-in-all', () => confidentInAll)
        .element()}
    </section>
  );
};
