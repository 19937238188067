import { Fragment } from 'react';
import { Divider, Typography } from '@mui/material';
import { calcScaleScore, SimpleFreqScales } from './helpers';
import { select } from './select';

import type { FeedbackComponentProps } from '.';
import type { state } from 'app';
import { ExtLink } from 'components';

type Config = {
  range: number[];
  genderFactors: {
    female: Array<[number, number]>;
    other: Array<[number, number]>;
  };
};

const evaluateScore = ({ range, genderFactors }: Config) => {
  const scaleScore = calcScaleScore(
    range.map((i) => `s1#q${i}`),
    {
      [SimpleFreqScales[0]]: 0,
      [SimpleFreqScales[1]]: 1,
      [SimpleFreqScales[2]]: 2,
      [SimpleFreqScales[3]]: 3,
    },
  );

  return (userSurvey: state.Survey, gender: string, grade: number) => {
    const rawScore = scaleScore(userSurvey);
    const factors = gender === 'Female' ? genderFactors.female : genderFactors.other;
    const [factor1, factor2] = factors[Math.floor((grade - 5) / 2)]! || factors[0]!;
    const score = 50 + ((rawScore - factor1) * 10) / factor2;
    // console.log(`rawScore: ${rawScore} genderIdentity: ${gender}, grade: ${grade}, score: ${score}`);
    return score;
  };
};

export const evaluateAnxiety = evaluateScore({
  range: [2, 3, 5, 6, 7, 9, 11, 12, 14, 17, 18, 20, 22, 23, 25],
  genderFactors: {
    female: [
      [7.29, 5.25],
      [5.8, 3.91],
      [5.94, 5.27],
      [5.76, 3.97],
    ],
    other: [
      [6.1, 4.15],
      [5.27, 3.95],
      [6.23, 4.56],
      [4.66, 3.58],
    ],
  },
});

export const evaluateDepression = evaluateScore({
  range: [1, 4, 8, 10, 13, 15, 16, 19, 21, 24],
  genderFactors: {
    female: [
      [3.75, 3.63],
      [3.6, 3.37],
      [3.97, 3.25],
      [4.91, 3.17],
    ],
    other: [
      [3.62, 2.87],
      [3.54, 3.18],
      [5.21, 3.51],
      [3.94, 3.88],
    ],
  },
});

const calcAnxientyAndDepression = (childGenderIdentity: string, rcadsSurvey: state.Survey): [number, number] => {
  const { 's0#childGrade': childGradeValue } = rcadsSurvey;
  const childGrade: number = Number(childGradeValue) ?? 9;
  return [
    evaluateAnxiety(rcadsSurvey, childGenderIdentity, childGrade) >= 65 ? 1 : 0,
    evaluateDepression(rcadsSurvey, childGenderIdentity, childGrade) >= 65 ? 1 : 0,
  ];
};

// Check if both childGrade and childNameIdentity are provided in step 0
// Need to complete at least 23 questions out of 25 to generate rcads feedback
export const rcadsCompleted = (rcadsSurvey: state.Survey) => {
  return rcadsSurvey._total > 0 && rcadsSurvey._step > 0 && rcadsSurvey._completed / rcadsSurvey._total > 0.9;
};

// How many more questions are required in order to get personalised feedback
// for RCADS
export const rcadsQuestionsRequiredToGetPersonalisedFeedback = (userSurvey: state.Survey) => {
  const questionsRequiredForNinetyPercent = Math.ceil(0.9 * userSurvey._total);
  const questionNumToCompletion =
    userSurvey._completed < questionsRequiredForNinetyPercent
      ? questionsRequiredForNinetyPercent - userSurvey._completed
      : 0;
  return questionNumToCompletion;
};

/**
 * RcadsComparative wants to show standard Rcads but using the child gender identity from the followup rcads
 * survey. So, we create an interface for showing rcads standard feedback given a specific child gender identity
 */
const RcadsStandardImpl = (props: {
  vocab: Record<string, string>;
  userSurvey: state.Survey;
  childGenderIdentity: string;
}) => {
  if (!rcadsCompleted(props.userSurvey)) {
    return null;
  }
  const { childNameYourteen, childNameyourteen: childName, them, they, are } = props.vocab;
  const [anxiety, depression] = calcAnxientyAndDepression(props.childGenderIdentity, props.userSurvey);
  const bodyIndex = anxiety * 2 + depression * 1;
  return select(
    <section>
      <Typography key="intro" variant="h5">
        {childNameYourteen}’s mental wellbeing
      </Typography>
      <Divider />

      <Fragment key="body">
        <p key="body_no_anxiety_no_depression" aria-label="body_no_anxiety_no_depression">
          Thanks for answering the questions about {childName}’s thoughts and feelings. Based on your responses,{' '}
          {childName} does <strong>not</strong> seem to be experiencing significant symptoms of anxiety or depression.
          Keep in mind that these questions only give us a snapshot of {childName}
          ’s mental health, and are not a replacement for professional advice. You know your child best – if you are
          ever worried about {childName}’s mental health, we suggest you check in with {them} about how {they} {are}{' '}
          feeling. You can also speak with your GP or health professional.
        </p>
        <p key="body_no_anxiety_but_depression" aria-label="body_no_anxiety_but_depression">
          Thanks for answering the questions about {childName}’s thoughts and feelings. Based on your responses,{' '}
          {childName} could be experiencing symptoms of depression. Keep in mind that these questions only give us a
          snapshot of {childName}’s mental health, and are not a replacement for professional advice. We suggest you
          check in with {childName} about how {they} {are} feeling and also consider seeking professional support (e.g.
          from a GP, school counsellor, or psychologist). You can find out more about accessing professional mental
          health support on the <ExtLink href="/resources">Useful resources</ExtLink> page.
        </p>

        <p key="body_anxiety_no_depression" aria-label="body_anxiety_no_depression">
          Thanks for answering the questions about {childName}’s thoughts and feelings. Based on your responses,{' '}
          {childName} could be experiencing symptoms of anxiety. Keep in mind that these questions only give us a
          snapshot of {childName}’s mental health, and are not a replacement for professional advice. We suggest you
          check in with {childName} about how {they} {are} feeling and also consider seeking professional support (e.g.
          from a GP, school counsellor, or psychologist). You can find out more about accessing professional mental
          health support on the <ExtLink href="/resources">Useful resources</ExtLink> page.
        </p>

        <p key="body_anxiety_depression" aria-label="body_anxiety_depression">
          Thanks for answering the questions about {childName}’s thoughts and feelings. Based on your responses,{' '}
          {childName} could be experiencing symptoms of anxiety and depression. Keep in mind that these questions only
          give us a snapshot of {childName}’s mental health, and are not a replacement for professional advice. We
          suggest you check in with {childName} about how {they} {are} feeling and also consider seeking professional
          support (e.g. from a GP, school counsellor, or psychologist). You can find out more about accessing
          professional mental health support on the <ExtLink href="/resources">Useful resources</ExtLink> page.
        </p>
      </Fragment>
    </section>,
  )
    .where('body', (_, i) => i === bodyIndex)
    .element();
};

export const RcadsStandard = ({ vocab, userSurvey, user }: FeedbackComponentProps) => {
  // Sanity check that we have a user
  if (!user) {
    return null;
  }
  return <RcadsStandardImpl userSurvey={userSurvey} vocab={vocab} childGenderIdentity={user.childGenderIdentity} />;
};

export const RcadsComparative = ({ vocab, userSurvey, allUserSurveys, user }: FeedbackComponentProps) => {
  // Sanity check that we have a user
  if (!user) {
    return null;
  }
  const baselineRcads = allUserSurveys['s4-rcads-hs'];
  const followupRcads = allUserSurveys['f4-rcads-hs'];
  const baselineCompleted = baselineRcads && rcadsCompleted(baselineRcads);
  const followupCompleted = followupRcads && rcadsCompleted(followupRcads);
  // console.log(
  //   `s4-rcads-hs: ${baselineRcads?._completed} question(s) completed; f4-rcads-hs: ${followupRcads?._completed} question(s) completed`,
  // );
  const followup = userSurvey;
  // Compare RCADS results only when both baseline and followup are complete
  if (!baselineCompleted || !followupCompleted) {
    return (
      <RcadsStandardImpl
        vocab={vocab}
        userSurvey={followup}
        childGenderIdentity={userSurvey['s0#childGenderIdentity']}
      />
    );
  }
  const { childNameYourteen, childNameyourteen: childName } = vocab;

  // ba: baseline anxiety; fa: followup anxiety: bd: baseline depression; fd: followup depression
  const [ba, bd] = calcAnxientyAndDepression(user.childGenderIdentity, baselineRcads);
  const [fa, fd] = calcAnxientyAndDepression(userSurvey['s0#childGenderIdentity'], followup);
  // index range: 0-15
  const index = (fa * 2 + ba) * 4 + (fd * 2 + bd);

  return select(
    <section>
      <Typography key="intro" variant="h5">
        {childNameYourteen}’s mental wellbeing
      </Typography>
      <Divider />

      <Fragment key="body">
        <p key="body_nofa_noba_nofd_nobd" aria-label="body_nofa_noba_nofd_nobd">
          Thanks for answering the questions about {childName}’s thoughts and feelings. Based on your responses today,
          it seems that {childName} may be experiencing <em>similar</em> symptoms of anxiety and depression than a few
          months ago. Your responses today suggest that {childName} is <strong>not</strong> experiencing significant
          symptoms of depression or anxiety.
        </p>
        <p key="body_nofa_noba_nofd_bd" aria-label="body_nofa_noba_nofd_bd">
          Thanks for answering the questions about {childName}’s thoughts and feelings. Based on your responses today,
          it seems that {childName} may be experiencing <em>fewer</em> symptoms of depression compared to a few months
          ago, and their symptoms of anxiety have remained in the normal range. Your responses today suggest that{' '}
          {childName} is <strong>not</strong> experiencing significant symptoms of depression or anxiety.
        </p>

        <p key="body_nofa_noba_fd_nobd" aria-label="body_nofa_noba_fd_nobd">
          Thanks for answering the questions about {childName}’s thoughts and feelings. Based on your responses today,
          it seems that {childName} may be experiencing <em>similar</em> symptoms of anxiety, but <em>more</em> symptoms
          of depression, than a few months ago. Your responses today suggest that {childName} is <strong>not</strong>{' '}
          experiencing significant symptoms of anxiety, but could be experiencing symptoms of depression.
        </p>
        <p key="body_nofa_noba_fd_bd" aria-label="body_nofa_noba_fd_bd">
          Thanks for answering the questions about {childName}’s thoughts and feelings. Based on your responses today,
          it seems that {childName} may be experiencing <em>similar</em> symptoms of anxiety and depression than a few
          months ago. Your responses today suggest that {childName} is <strong>not</strong> experiencing significant
          symptoms of anxiety, but could be experiencing symptoms of depression.
        </p>
        <p key="body_nofa_ba_nofd_nobd" aria-label="body_nofa_ba_nofd_nobd">
          Thanks for answering the questions about {childName}’s thoughts and feelings. Based on your responses today,
          it seems that {childName} may be experiencing <em>fewer</em> symptoms of anxiety compared to a few months ago,
          and their symptoms of depression have remained in the normal range. Your responses today suggest that{' '}
          {childName} is <strong>not</strong> experiencing significant symptoms of anxiety or depression.
        </p>
        <p key="body_nofa_ba_nofd_bd" aria-label="body_nofa_ba_nofd_bd">
          Thanks for answering the questions about {childName}’s thoughts and feelings. Based on your responses today,
          it seems that {childName} may be experiencing <em>fewer</em> symptoms of anxiety and depression than a few
          months ago. Your responses today suggest that {childName} is <strong>not</strong> experiencing significant
          symptoms of anxiety or depression.
        </p>
        <p key="body_nofa_ba_fd_nobd" aria-label="body_nofa_ba_fd_nobd">
          Thanks for answering the questions about {childName}’s thoughts and feelings. Based on your responses today,
          it seems that {childName} may be experiencing <em>fewer</em> symptoms of anxiety, but <em>more</em> symptoms
          of depression, than a few months ago. Your responses today suggest that {childName} is <strong>not</strong>{' '}
          experiencing significant symptoms of anxiety, but could be experiencing symptoms of depression.
        </p>
        <p key="body_nofa_ba_fd_bd" aria-label="body_nofa_ba_fd_bd">
          Thanks for answering the questions about {childName}’s thoughts and feelings. Based on your responses today,
          it seems that {childName} may be experiencing <em>fewer</em> symptoms of anxiety, but not <em>fewer</em>{' '}
          symptoms of depression, compared to a few months ago. Your responses today suggest that {childName} is{' '}
          <strong>not</strong> experiencing significant symptoms of anxiety, but could still be experiencing symptoms of
          depression.
        </p>
        <p key="body_fa_noba_nofd_nobd" aria-label="body_fa_noba_nofd_nobd">
          Thanks for answering the questions about {childName}’s thoughts and feelings. Based on your responses today,
          it seems that {childName} may be experiencing <em>more</em> symptoms of anxiety, but <em>similar</em> symptoms
          of depression, compared to a few months ago. Your responses today suggest that {childName} is{' '}
          <strong>not</strong> experiencing significant symptoms of depression, but could be experiencing symptoms of
          anxiety.
        </p>
        <p key="body_fa_noba_nofd_bd" aria-label="body_fa_noba_nofd_bd">
          Thanks for answering the questions about {childName}’s thoughts and feelings. Based on your responses today,
          it seems that {childName} may be experiencing <em>fewer</em> symptoms of depression, but <em>more</em>{' '}
          symptoms of anxiety, than a few months ago. Your responses today suggest that {childName} is{' '}
          <strong>not</strong> experiencing significant symptoms of depression, but could be experiencing symptoms of
          anxiety.
        </p>
        <p key="body_fa_noba_fd_nobd" aria-label="body_fa_noba_fd_nobd">
          Thanks for answering the questions about {childName}’s thoughts and feelings. Based on your responses today,
          it seems that {childName} may be experiencing <em>more</em> symptoms of anxiety and depression than a few
          months ago. Your responses today suggest that {childName} could be experiencing symptoms of depression and
          anxiety.
        </p>
        <p key="body_fa_noba_fd_bd" aria-label="body_fa_noba_fd_bd">
          Thanks for answering the questions about {childName}’s thoughts and feelings. Based on your responses today,
          it seems that {childName} may be experiencing <em>more</em> symptoms of anxiety, but <em>similar</em> symptoms
          of depression, compared to a few months ago. Your responses today suggest that {childName} could be
          experiencing symptoms of anxiety and depression.
        </p>
        <p key="body_fa_ba_nofd_nobd" aria-label="body_fa_ba_nofd_nobd">
          Thanks for answering the questions about {childName}’s thoughts and feelings. Based on your responses today,
          it seems that {childName} may be experiencing <em>similar</em> symptoms of anxiety and depression, compared to
          a few months ago. Your responses today suggest that {childName} is <strong>not</strong> experiencing
          significant symptoms of depression, but could be experiencing symptoms of anxiety.
        </p>
        <p key="body_fa_ba_nofd_bd" aria-label="body_fa_ba_nofd_bd">
          Thanks for answering the questions about {childName}’s thoughts and feelings. Based on your responses today,
          it seems that {childName} may be experiencing <em>fewer</em> symptoms of depression, but not <em>fewer</em>{' '}
          symptoms of anxiety, compared to a few months ago. Your responses today suggest that {childName} is{' '}
          <strong>not</strong> experiencing significant symptoms of depression, but could be experiencing symptoms of
          anxiety.
        </p>
        <p key="body_fa_ba_fd_nobd" aria-label="body_fa_ba_fd_nobd">
          Thanks for answering the questions about {childName}’s thoughts and feelings. Based on your responses today,
          it seems that {childName} may be experiencing <em>similar</em> symptoms of anxiety, but <em>more</em> symptoms
          of depression, than a few months ago. Your responses today suggest that {childName} could be experiencing
          symptoms of anxiety and depression.
        </p>
        <p key="body_fa_ba_fd_bd" aria-label="body_fa_ba_fd_bd">
          Thanks for answering the questions about {childName}’s thoughts and feelings. Based on your responses today,
          it seems that {childName} may be experiencing <em>similar</em> symptoms of anxiety and depression than a few
          months ago. Your responses today suggest that {childName} could be experiencing symptoms of anxiety and
          depression.
        </p>
      </Fragment>

      <Typography key="end" variant="body1">
        Keep in mind that these questions only give us a snapshot of {childName}’s mental health, and are not a
        replacement for professional advice. If you are worried about {childName}’s mental health or feel they need
        additional support, we suggest you check in with {childName} about how they are feeling and consider speaking to
        a health professional (e.g., a GP, school counsellor, or psychologist). You can find out more about accessing
        professional mental health support <ExtLink href="/resources">here</ExtLink>.
      </Typography>
    </section>,
  )
    .where('body', (_, i) => i === index)
    .element();
};
