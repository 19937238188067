import { Box, Button, Typography } from '@mui/material';
import { markCompleted, surveyCompleted, updateSurvey, useAppDispatch } from 'app/store';
import { QuestionStepper } from './questions';
import { proceedWithErrorsDialogs, proceedWithoutAnsweringAllQuestionsDialog, surveyProgress } from './helpers';
import { Timestamp } from 'firebase/firestore';
import type { state, data } from 'app';
import { navigate } from 'gatsby';
import { ReactElement, useState } from 'react';
import { Dialog } from 'components/Dialog';

export type SurveyQuestionsProps = {
  activeStep: number;
  survey: data.Survey;
  userSurvey: state.Survey;
  isLast: boolean;
  nextSurveyKey?: string | undefined;
};

export function SurveyQuestions(props: SurveyQuestionsProps) {
  const { activeStep, survey, userSurvey, isLast } = props;
  // If the user tries to proceed to the next section of the survey for topic planning
  // without answering all of the questions, we show them a warning dialog
  const [proceedWarningDialog, setProceedWarningDialog] = useState<{ title: string; content: string } | undefined>(
    undefined,
  );
  const [proceedWithoutAnsweringAllQuestionsDialogState, setProceedWithoutAnsweringAllQuestionsDialogState] = useState<
    | {
        title?: string;
        content?: (props: { questionsAnswered: number; totalQuestions: number }) => ReactElement;
        show: false;
      }
    | {
        title: string;
        content: (props: { questionsAnswered: number; totalQuestions: number }) => ReactElement;
        show: true;
      }
  >({ show: false });
  const dispatch = useAppDispatch();

  const handleChange = (data: Record<string, any>) => {
    const [_total, _completed] = surveyProgress(survey.sections.data, data as state.Survey);
    dispatch(updateSurvey({ id: survey.key, data: { ...data, _total, _completed } }));
  };

  const setStep = (step: number) => {
    if (step > activeStep) {
      dispatch(updateSurvey({ id: survey.key, data: { _step: step, _submittedAt: Timestamp.now() } }));
    } else {
      dispatch(updateSurvey({ id: survey.key, data: { _step: step } }));
    }
    if (step === survey.sections.data.length) {
      dispatch(surveyCompleted({ surveyKey: survey.key }));
    }
    if (isLast && step === survey.sections.data.length) {
      dispatch(markCompleted({ scope: survey.scope === 'baseline' ? 'survey' : 'followup' }));
    }
  };

  const handleSetStep = (step: number) => {
    const dialog = proceedWithoutAnsweringAllQuestionsDialog[survey.key];
    if ((userSurvey._total === 0 || userSurvey._completed < userSurvey._total) && dialog) {
      setProceedWithoutAnsweringAllQuestionsDialogState({
        content: dialog.content,
        title: dialog.title,
        show: true,
      });
      return;
    }
    setStep(step);
  };

  const goToNextSurveyOrDashboard = () => {
    if (props.nextSurveyKey) {
      navigate(`/surveys/${props.nextSurveyKey}`);
      return;
    }
    navigate(`/dashboard#topic`);
  };

  const handleSkipSurveyRequest = () => {
    const dialog = proceedWithErrorsDialogs[survey.key];
    setProceedWarningDialog(dialog);
    if (!dialog) {
      goToNextSurveyOrDashboard();
    }
  };

  const handleClickNextWithErrors = () => {
    const dialog = proceedWithErrorsDialogs[survey.key];
    if (dialog !== undefined) {
      setProceedWarningDialog(dialog);
    }
  };

  return (
    <Box>
      {proceedWarningDialog ? (
        <Dialog open onClose={() => setProceedWarningDialog(undefined)} sx={{ p: 4 }}>
          <Dialog.Title>{proceedWarningDialog.title}</Dialog.Title>
          <Dialog.Content>{proceedWarningDialog.content}</Dialog.Content>
          <Dialog.Actions>
            <Button sx={{ width: { xs: '100%', sm: 'auto' } }} onClick={goToNextSurveyOrDashboard}>
              Skip this survey
            </Button>
            <Button
              sx={{ width: { xs: '100%', sm: 'auto' }, marginLeft: '0 !important' }}
              variant="contained"
              onClick={() => setProceedWarningDialog(undefined)}
            >
              Back to survey
            </Button>
          </Dialog.Actions>
        </Dialog>
      ) : null}
      <Dialog
        open={proceedWithoutAnsweringAllQuestionsDialogState.show}
        onClose={() => setProceedWithoutAnsweringAllQuestionsDialogState((s) => ({ ...s, show: false }))}
      >
        <Dialog.Title>{proceedWithoutAnsweringAllQuestionsDialogState?.title}</Dialog.Title>
        <Dialog.Content>
          {proceedWithoutAnsweringAllQuestionsDialogState?.content?.({
            totalQuestions: userSurvey._total,
            questionsAnswered: userSurvey._completed,
          })}
        </Dialog.Content>
        <Dialog.Actions>
          <Button sx={{ width: { xs: '100%', sm: 'auto' } }} onClick={() => setStep(userSurvey._step + 1)}>
            Confirm
          </Button>
          <Button
            sx={{ width: { xs: '100%', sm: 'auto' }, marginLeft: '0 !important' }}
            variant="contained"
            onClick={() => setProceedWithoutAnsweringAllQuestionsDialogState((s) => ({ ...s, show: false }))}
          >
            Back to survey
          </Button>
        </Dialog.Actions>
      </Dialog>
      <Typography variant="h4" gutterBottom>
        {survey.title}
      </Typography>
      <Button
        color="primary"
        sx={{ textTransform: 'unset', fontWeight: 'bold', borderRadius: '0.5rem', mb: 2 }}
        onClick={handleSkipSurveyRequest}
      >
        Skip this survey
      </Button>
      <QuestionStepper
        step={activeStep}
        setStep={handleSetStep}
        data={userSurvey}
        onChange={handleChange}
        sections={survey.sections.data}
        finishLabel={isLast ? 'Finish' : 'Submit'}
        onNextClickWithErrors={handleClickNextWithErrors}
      />
    </Box>
  );
}
