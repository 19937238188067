import { styled } from '@mui/material';

export const Article = styled('article')(({ theme }) => {
  return {
    ...theme.typography.body1,

    '&  ul': {
      paddingLeft: '1.5rem',
      listStyle: `square outside url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 24 24'%3E%3Cpath d='M22 12c0-5.52-4.48-10-10-10S2 6.48 2 12s4.48 10 10 10 10-4.48 10-10zm-10 1H8v-2h4V8l4 4-4 4v-3z'%3E%3C/path%3E%3C/svg%3E")`,
    },

    '&  ul li': {
      lineHeight: 1.2,
      margin: theme.spacing(1.5, 0),
      '&::marker': {
        lineHeight: 1,
      },
    },
  };
});
