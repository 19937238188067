import { useEffect, useMemo } from 'react';
import { graphql } from 'gatsby';
import { Box, Breadcrumbs, Typography, Container, LinearProgress } from '@mui/material';
import { Link } from 'components/Link';
import { headings } from 'app';
import { SitePage } from 'app/layout';
import { createBaseline, useAppDispatch, useAppSelector } from 'app/store';
import { ensureAuth } from 'app/helpers';
import { SurveyQuestions } from 'app/survey/SurveyQuestions';
import { SurveyFeedback } from 'app/survey/SurveyFeedback';
import { contextualSurvey, nextSurveyInPathway } from 'app/survey/helpers';

import type { state, data } from 'app';

type SurveyTemplateProps = {
  pageContext: PageProps['pageContext'];
  allUserSurveys: Record<string, state.Survey>;
  user: state.User;
  userSurvey: state.Survey;
  survey: data.Survey;
};

export function SurveyTemplate(props: SurveyTemplateProps) {
  const { user, userSurvey, survey, pageContext, allUserSurveys } = props;
  const { nextId, prevId } = pageContext;
  const activeStep = userSurvey._step || 0;
  const inprogress = activeStep < survey.sections.data.length;
  const normalizedSurvey = useMemo(() => contextualSurvey(survey, user), [user, survey]);
  const nextSurveyKey = nextSurveyInPathway(user, survey.key, survey.scope);

  const progress = userSurvey._total > 0 ? userSurvey._completed / userSurvey._total : 0;

  return (
    <SitePage noAppBarShadow>
      <LinearProgress
        variant="determinate"
        value={progress * 100}
        sx={{ position: 'sticky', top: 0, left: 0, right: 0 }}
      />
      <Container>
        <Box pt={2} mb={2}>
          <Breadcrumbs aria-label="breadcrumb-feedback">
            {survey.scope === 'baseline' ? (
              <Link color="inherit" href="/dashboard#baseline">
                Dashboard
              </Link>
            ) : (
              <Link color="inherit" href="/dashboard/#followup">
                Dashboard
              </Link>
            )}
            <Typography color="textSecondary">
              {survey.scope === 'baseline' ? headings.baseline : headings.followup}
            </Typography>
            <Typography color="textPrimary">{normalizedSurvey.title}</Typography>
          </Breadcrumbs>
        </Box>

        {inprogress ? (
          <SurveyQuestions
            activeStep={activeStep}
            survey={normalizedSurvey}
            userSurvey={userSurvey}
            isLast={!nextId}
            nextSurveyKey={nextSurveyKey}
          />
        ) : (
          <SurveyFeedback
            user={user}
            survey={normalizedSurvey}
            userSurvey={userSurvey}
            allUserSurveys={allUserSurveys}
            nextId={nextId}
            prevId={prevId}
          />
        )}
      </Container>
    </SitePage>
  );
}

type PageProps = {
  data: {
    survey: data.Survey;
  };
  pageContext: {
    nextId: string;
    prevId: string;
    followupIds: string[];
    baselineIds: string[];
  };
};

export default ensureAuth((props: PageProps) => {
  const {
    data: { survey },
    pageContext,
  } = props;
  const surveyId = survey.key;

  const dispatch = useAppDispatch();
  const { user, surveys: userSurveys } = useAppSelector((state) => state.profile.data!);
  const userSurvey = userSurveys[surveyId];

  useEffect(() => {
    if (!userSurvey) {
      dispatch(createBaseline({ surveyId }));
    }
  }, [userSurvey]);

  if (!userSurvey) {
    return <div>creating survey</div>;
  }

  return (
    <SurveyTemplate
      pageContext={pageContext}
      user={user}
      survey={survey}
      allUserSurveys={userSurveys}
      userSurvey={userSurvey}
    />
  );
});

export const query = graphql`
  query ($key: String) {
    survey: strapiSurvey(key: { eq: $key }) {
      ...SurveyFragment
    }
  }

  fragment SurveyFragment on STRAPI_SURVEY {
    key
    title
    tint
    extraStyle {
      left
      bottom
      height
    }
    scope
    cover {
      localFile {
        publicURL
      }
    }
    sections {
      data: strapi_json_value {
        key
        title
        type
        scales
        options
        required
        questions {
          key
          label
          title
          type
          cond
          scales
          size
          options
          answers
          variant
          content
          required
          helperText
          showDisclaimer
        }
      }
    }
  }
`;
