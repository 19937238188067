import { useEffect, useMemo } from 'react';
import { navigate } from 'gatsby';
import { Box, Button, Card, CardHeader, CardContent, Alert } from '@mui/material';
import { Feedback } from 'app/feedback';
import type { state, data } from 'app';
import { nextSurveyInPathway } from './helpers';
import { rcadsCompleted, rcadsQuestionsRequiredToGetPersonalisedFeedback } from 'app/feedback/Rcads';
import { updateSurvey, useAppDispatch } from 'app/store';

export type SurveyFeedbackProps = {
  survey: data.Survey;
  user: state.User;
  userSurvey: state.Survey;
  nextId: string | null;
  prevId: string | null;
  allUserSurveys: Record<string, state.Survey>;
};

export function SurveyFeedback(props: SurveyFeedbackProps) {
  const { survey, user, userSurvey, allUserSurveys, prevId, nextId } = props;
  const prevDisabled = !prevId;
  const dispatch = useAppDispatch();
  const handleRestart = () => {
    dispatch(updateSurvey({ id: survey.key, data: { _step: 0 } }));
  };

  useEffect(() => {
    document.querySelector('main')?.scrollTo({ top: 0 });
  }, []);

  // The number of questions the user has to answer to get personalised feedback
  const questionsRequiredToGetPersonalisedFeedback = useMemo(() => {
    // If this isn't an RCADS survey and we are on the SurveyFeedback page, then
    // we can personalise the feedback (since this implies that the survey is complete)
    if (survey.key !== 's4-rcads-hs' && survey.key !== 'f4-rcads-hs') {
      return 0;
    }
    return rcadsQuestionsRequiredToGetPersonalisedFeedback(userSurvey);
  }, [userSurvey, survey]);

  // To have the "Next Survey" button in baseline surveys, we need to look up
  // the next survey key based on the user's pathway
  const nextBaselineKey: string | undefined = useMemo(() => {
    return nextSurveyInPathway(user, survey.key, survey.scope);
  }, [survey, user]);

  return (
    <Box pb={4}>
      <Card sx={{ mt: 4 }}>
        <CardHeader title="Feedback" />
        {questionsRequiredToGetPersonalisedFeedback !== 0 ? (
          <Alert severity="warning" sx={{ p: 2, alignItems: 'center' }}>
            <Box mt={1}>
              Sorry, you haven't answered enough questions for us to score the survey and provide you with feedback
              about your teenager's mental health. If you'd like to receive this feedback, you'll need to answer{' '}
              <strong>{questionsRequiredToGetPersonalisedFeedback}</strong> more questions. To answer these questions,
              click "go back to survey questions". If you'd prefer not to answer, you can continue to your modules by
              clicking the button below.
              <Button onClick={() => handleRestart()}>go back to survey questions</Button>
            </Box>
          </Alert>
        ) : (
          <CardContent>
            <Feedback surveyId={survey.key} user={user} userSurvey={userSurvey} allUserSurveys={allUserSurveys} />
          </CardContent>
        )}
        {survey.scope === 'baseline' && (
          <Box mt={2} p={2} gap={2} display="flex" justifyContent="center">
            <Button variant="contained" color="primary" onClick={() => navigate(`/dashboard#topic`)}>
              Continue to modules
            </Button>
            {nextBaselineKey ? (
              <Button variant="contained" onClick={() => navigate(`/surveys/${nextBaselineKey}`)}>
                Next Survey
              </Button>
            ) : null}
          </Box>
        )}
        {survey.scope === 'followup' && (
          <Box mt={2} p={2} display="flex" justifyContent="space-between">
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate(`/surveys/${prevId}`)}
              disabled={prevDisabled}
              aria-disabled={prevDisabled}>
              Prev
            </Button>
            {nextId ? (
              <Button color="primary" onClick={() => navigate(`/surveys/${nextId}`)}>
                Next
              </Button>
            ) : (
              <Button color="primary" onClick={() => navigate(`/dashboard#${survey.scope}`)}>
                Back to dashboard
              </Button>
            )}
          </Box>
        )}
      </Card>
    </Box>
  );
}
