import { Typography } from '@mui/material';

export const Evaluation = () => {
  return (
    <section>
      <Typography>
        Thank you for answering these questions, your feedback is much appreciated. Please continue to the post-program
        surveys about your parenting and your teenager's mental wellbeing by clicking 'next'.
      </Typography>
    </section>
  );
};
