import { usersVocabulary } from '@pip-headspace/common';
import { Typography } from '@mui/material';
import { Pradas } from './Pradas';
import { Article } from './Article';
import type { ComponentType } from 'react';
import type { state } from 'app';
import type { FeedbackComponentProps } from '.';
import { Confidence } from './Confidence';
import { Evaluation } from './Evaluation';
import { RcadsStandard, RcadsComparative } from './Rcads';

export const feedbacks: Record<string, ComponentType<FeedbackComponentProps>> = {
  's1-pses-hs': Confidence,
  's2-pradas-hs': Pradas,
  's3-pradas-pses-hs': Pradas,
  's4-rcads-hs': RcadsStandard,
  'f1-evaluation-hs': Evaluation,
  'f2-pradas-pses-hs': Pradas,
  'f4-rcads-hs': RcadsComparative,
};

export type FeedbackProps = {
  surveyId: string;
  user: state.User;
  userSurvey: state.Survey;
  allUserSurveys: Record<string, state.Survey>;
};

export function Feedback(props: FeedbackProps) {
  const { user, surveyId, userSurvey, allUserSurveys } = props;
  const FeedbackComp = feedbacks[surveyId];
  const vocab = usersVocabulary(user);
  return (
    <Article>
      {FeedbackComp ? (
        <FeedbackComp user={user} userSurvey={userSurvey} vocab={vocab} allUserSurveys={allUserSurveys} />
      ) : (
        <Typography color="error">No feedback for {surveyId}</Typography>
      )}
    </Article>
  );
}
