import { Fragment } from 'react';
import sum from 'lodash/sum';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { ExtLink } from 'components/Link';
import { calcOptionsScores, LikelyScales } from './helpers';
import { select, hasValue, valueInRange, Predicate } from './select';
import type { FeedbackComponentProps } from '.';
import { ExpandMore } from '@mui/icons-material';

export const evaluatePradas9 = calcOptionsScores([
  ['S9#Q1', [LikelyScales[2], LikelyScales[3]]],
  ['S9#Q2', [LikelyScales[0], LikelyScales[1]]],
  ['S9#Q3', [LikelyScales[2], LikelyScales[3]]],
  ['S9#Q4', [LikelyScales[2], LikelyScales[3]]],
  ['S9#Q5', [LikelyScales[0], LikelyScales[1]]],
  ['S9#Q6', [LikelyScales[2], LikelyScales[3]]],
  ['S9#Q7', [LikelyScales[2], LikelyScales[3]]],
  ['S9#Q8', [LikelyScales[2], LikelyScales[3]]],
  ['S9#Q9', [LikelyScales[2], LikelyScales[3]]],
]);

export const Pradas9 = ({ vocab, userSurvey: state }: FeedbackComponentProps) => {
  const { childNameyourteen, childNameYourteen, their, them, they, themselves, think, are, need } = vocab;
  const scores = evaluatePradas9(state);
  const moodGroup = scores.slice(0, 4);

  const sumScore = sum(scores);
  const sumMoodGroup = sum(moodGroup);

  const matchConditions = (): Predicate => {
    const applies: Record<string, boolean> = {
      '10_mood_start': sumScore === 8 && sumMoodGroup < 4,
    };
    return (elm) => {
      const apply = elm.key ? applies[elm.key] : undefined;
      return apply === undefined ? true : apply;
    };
  };

  const details = select(
    <AccordionDetails>
      <p key="10_body" aria-label="10_body">
        During adolescence, a lot of changes occur in a person’s emotions, thinking, and behaviour. This can make it
        hard to tell whether a change is due to depression or an anxiety disorder, or is part of normal adolescent
        development.
      </p>

      <Fragment key="list_intro">
        <p key="10_score_low" aria-label="10_score_low">
          If you do notice a persistent change in {childNameyourteen}’s mood or behaviour:
        </p>
        <p key="10_score_high" aria-label="10_score_high">
          You have a good idea of what to do if you think {childNameyourteen} needs professional help for depression or
          anxiety, which is important in protecting {their} health and wellbeing.
        </p>
      </Fragment>

      <p key="10_mood_start" aria-label="10_mood_start">
        If you do notice a persistent change in {childNameyourteen}’s mood or behaviour:
      </p>

      <ul key="mood_list" aria-label="mood_list">
        <li key="10_mood_1_a" aria-label="10_mood_1_a">
          Encourage {them} to talk to you about what’s on {their} mind, and really listen to what {they} {are} saying.
        </li>
        <li key="10_mood_2_c" aria-label="10_mood_2_c">
          Don’t dismiss {their} feelings, or tell {them} to ‘snap out of it.’ Instead, show concern and encourage {them}{' '}
          to talk to you about what’s going on for {them}.
        </li>
        <li key="10_mood_3_a" aria-label="10_mood_3_a">
          Try to determine whether the change in mood or behaviour is caused by a temporary situation or a more ongoing
          problem.
        </li>
        <li key="10_mood_4_a" aria-label="10_mood_4_a">
          Take {them} to a trained mental health professional.
        </li>
      </ul>

      <Fragment key="para">
        <section key="10_para_5_d" aria-label="10_para_5_d">
          <p>
            While it is important to seek help if you think that {childNameyourteen} may be experiencing an anxiety
            disorder, remember that everyone experiences anxiety sometimes, and that normal anxiety is not necessarily a
            problem. Seeking help too soon may actually increase {childNameyourteen}’s anxiety. Rather than seeking help
            at the very first sign of anxiety, try to determine whether the problem is serious and persistent, and needs
            professional help. You should seek professional help if:
          </p>
          <ul>
            <li>Your attempts to help reduce {childNameyourteen}’s anxiety don’t work.</li>

            <li>{childNameYourteen} experiences symptoms of anxiety for more than 6 months.</li>

            <li>
              Anxiety symptoms begin to take over {childNameyourteen}’s life or limit {their} activities.
            </li>
          </ul>
        </section>
        <p key="10_para_6_a" aria-label="10_para_6_a">
          If you think that you may be experiencing problems with depression or an anxiety disorder, it is important
          that you look after yourself. You can set an example for {childNameyourteen} by seeking professional help.
          Getting treatment may not only help you, but also {childNameyourteen}. By getting treatment yourself, you will
          be better able to support {childNameyourteen}.
        </p>
        <section key="10_para_11_a" aria-label="10_para_11_a">
          <p>
            To find out more about where to get help for depression or anxiety disorders, you can check out{' '}
            <ExtLink href="/resources">the Useful Resources here</ExtLink>.
          </p>
          <p>You can also talk to your family doctor or local health service.</p>
        </section>
        <section key="10_para_12_a" aria-label="10_para_12_a">
          <p>
            Provide {childNameyourteen} with information about where {they} can seek help for depression and anxiety
            disorders if {they} {need} to. For example, you could talk with {childNameyourteen} about where {they} could
            seek help (e.g. the school counselor or family doctor), put some information or resources up on the fridge,
            or give {them} a list of local services available.
          </p>
          <p>
            You can find some more resources for {childNameyourteen} <ExtLink href="/resources">here</ExtLink>.
          </p>
        </section>
        <p key="10_para_13_a" aria-label="10_para_13_a">
          Sometimes, it can be difficult for both parents and teenagers to accept that professional help is needed.
          Teenagers may be reluctant to seek help due to concerns about privacy or embarrassment. Encourage{' '}
          {childNameyourteen} to seek help {themselves} if {they} {think} {they} {need} it. However, if you believe that{' '}
          {childNameyourteen} needs professional help, you should seek help on {their} behalf even if {they} {are}{' '}
          reluctant.
        </p>
      </Fragment>

      <p key="10_end" aria-label="10_end">
        If you’d like to learn more about when and where to seek professional help, see the module{' '}
        <strong>
          <em>Understanding depression and anxiety</em>
        </strong>
        .
      </p>
    </AccordionDetails>,
  )
    .where(matchConditions())
    .where('list_intro', valueInRange(sumScore, [0, 8], [8, Number.MAX_VALUE]))
    .where('mood_list', hasValue<Number>(moodGroup, 0))
    .where('para', hasValue<Number>(scores.slice(4, 9), 0))
    .element();

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />} aria-controls="pradas-1" id="pradas-1">
        <Typography key="2_heading" color="darkPrimary.main" gutterBottom>
          Getting help when needed
        </Typography>
      </AccordionSummary>
      {details}
    </Accordion>
  );
};
