import { useMemo, Fragment } from 'react';
import { Link } from 'gatsby';
import range from 'lodash/range';
import { Divider, Typography } from '@mui/material';
import { calcScaleScore, OccasionScales } from './helpers';
import { select, valueInRange } from './select';

import type { FeedbackComponentProps } from '.';

export const evaluateK6 = calcScaleScore(
  range(1, 7).map((i) => `s1#q${i}`),
  {
    [OccasionScales[0]]: 0,
    [OccasionScales[1]]: 1,
    [OccasionScales[2]]: 2,
    [OccasionScales[3]]: 3,
    [OccasionScales[4]]: 4,
  },
);

export const K6 = ({ vocab, userSurvey }: FeedbackComponentProps) => {
  const { childName } = vocab;
  const element = useMemo(() => {
    const score = evaluateK6(userSurvey);
    return select(
      <section>
        <Typography key="heading" variant="h5">
          Your mental wellbeing
        </Typography>
        <Divider />

        <Fragment key="body">
          <p key="body_low" aria-label="body_low">
            Thanks for letting us know how you’ve been feeling lately. It’s great to see from your responses that you
            seem to be managing quite well emotionally. If this changes or you feel you need extra support, we encourage
            you to reach out to a trusted friend, family member, or health professional. You can check out the{' '}
            <Link to="/resources">Useful Resources</Link> page at any time for more information.
          </p>
          <p key="body_medium" aria-label="body_medium">
            Thanks for letting us know how you’ve been feeling lately. Based on your answers, it looks like you’ve been
            experiencing some distress. We encourage you to seek extra support from a trusted friend or family member,
            or health professional. After you complete this survey, we recommend that you check out the{' '}
            <Link to="/resources">Useful Resources</Link> page on our website for some suggestions of where you can seek
            professional support. Remember, looking after your own mental wellbeing is important not only for you, but
            also for {childName}.
          </p>
          <p key="body_high" aria-label="body_high">
            Thanks for letting us know how you’ve been feeling lately. We’re sorry to see that you’ve been feeling quite
            distressed. If you haven’t already, we encourage you to seek additional support from a health professional.
            After you complete this survey, you can check out the <Link to="/resources">Useful Resources</Link> page on
            our website for some suggestions of where you can seek professional support. Remember, looking after your
            own mental wellbeing is important not only for you, but also for {childName}.
          </p>
        </Fragment>
      </section>,
    )
      .where('body', valueInRange(score, [0, 5], [5, 13], [13, Number.MAX_VALUE]))
      .element();
  }, [userSurvey, vocab]);

  return (
    <>
      {element}
      <Typography variant="h6" gutterBottom>
        Next steps:
      </Typography>
      There is one more section of this survey package, which should take about 15-minutes. The next section will ask
      about your general approach to parenting {childName}. Based on your responses, you will receive tailored feedback
      and be recommended particular modules to complete. After you’ve completed the next section, you’ll be able to
      select your modules and get started with your program.
    </>
  );
};
